<template>
  <default-layout :breadcrumbs="[]">
    <h1 style="display: none">{{ $t('general.homepage') }}</h1>
    <search />
    <banner />
    <!--    <projects-->
    <!--      key="projects-subsidized-house"-->
    <!--      :category="'subsidized-house'"-->
    <!--      :title="$t('homepage.title.subsidizedHouse')"-->
    <!--      :seeAllUrl="'/cari/rumah-subsidi'"-->
    <!--    />-->
    <!--    <projects-->
    <!--      :useBackground="true"-->
    <!--      key="projects-listing"-->
    <!--      :category="'listing'"-->
    <!--      :title="$t('homepage.title.listing')"-->
    <!--      :seeAllUrl="'/cari/semua-tipe-properti/?listing_type_id=2'"-->
    <!--    />-->
    <projects
      key="projects-house-secondary"
      :category="'house-secondary'"
      :title="$t('homepage.title.houseSecondary')"
      :seeAllUrl="'/cari/rumah/?listing_type_id=2'"
    />
    <projects
      :useBackground="true"
      key="projects-apartment-secondary"
      :category="'apartment-secondary'"
      :title="$t('homepage.title.apartmentSecondary')"
      :seeAllUrl="'/cari/apartemen/?listing_type_id=2'"
    />
    <projects
      key="projects-house"
      :category="'house'"
      :title="$t('homepage.title.house')"
      :seeAllUrl="'/cari/rumah/?listing_type_id=1'"
    />
    <projects
      :useBackground="true"
      key="projects-apartment"
      :category="'apartment'"
      :title="$t('homepage.title.apartment')"
      :seeAllUrl="'/cari/apartemen/?listing_type_id=1'"
    />
    <consultation></consultation>
    <!--    <about></about>-->
    <media></media>
    <testimony></testimony>
  </default-layout>
</template>

<script>
import { defineAsyncComponent } from 'vue';
const DefaultLayout = defineAsyncComponent(() => import('@/layouts/default.vue'));
const Search = defineAsyncComponent(() => import('@/components/home/search.vue'));
const Projects = defineAsyncComponent(() => import('@/components/home/projects.vue'));
// import About from '@/components/home/about';
const Consultation = defineAsyncComponent(() => import('@/components/home/consultation.vue'));
const Banner = defineAsyncComponent(() => import('@/components/home/banner.vue'));
const Media = defineAsyncComponent(() => import('@/components/home/media.vue'));
const Testimony = defineAsyncComponent(() => import('@/components/home/testimony.vue'));

import DefaultHeadMixin from '@/mixins/defaultHead';

export default {
  name: 'index',
  mixins: [DefaultHeadMixin],
  components: {
    DefaultLayout,
    Search,
    Projects,
    // About,
    Consultation,
    Banner,
    Media,
    Testimony,
  },
  async mounted() {
    if (this.$store.state.global.loggedIn) {
      await this.$store.dispatch('v2/search/getShortlistUuids', {
        listingType: this.$store.state.v2.masters.listingTypeEnum.PRIMARY,
      });
    }

    if (process.client) {
      // Only open takeover modal for normal users, to make sure SEO is safe
      let self = this;
      setTimeout(() => {
        self.checkTakeover();
      }, 1000);
    }
  },
  methods: {
    async checkTakeover() {
      let takeover = null;
      const cookieStr = document.cookie;
      const cookies = require('cookie').parse(cookieStr || '') || {};
      if (cookies.belirumaTakeover) {
        takeover = cookies.belirumaTakeover;
      }

      if (!takeover) {
        let detail = await this.$store.dispatch('takeover/getTakeover');
        if (detail) {
          this.$modal.show('modal--takeover');
        }
      }
    },
  },
};
</script>
